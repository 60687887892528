<template>
  <v-container>
    <!-- Header -->
    <v-row class="d-flex justify-center align-center ma-2">
      <v-icon large left color="blue">mdi-file-edit-outline</v-icon>
      <div class="text-h5 pa-4 blue--text darken-2">เบิกเวชภัณฑ์</div>
      <v-spacer />
      <v-btn
        tile
        large
        color="blue darken-2 white--text"
        @click="showWithdrawDialog"
      >
        <v-icon class="pr-2">mdi-plus-thick</v-icon>
        สร้างรายการเบิกเวชภัณฑ์ใหม่
      </v-btn>
    </v-row>
    <v-divider />

    <!-- TAB HEADER -->
    <v-data-table
      :items-per-page="5"
      :items="withdrawTable.items"
      :headers="withdrawTable.header"
      :footer-props="{
        'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
        pageText: 'แสดง {0}-{1} จาก {2}',
      }"
    >
      <template v-slot:item.document="{}">
        <v-btn icon outlined>
          <v-icon>mdi-file-document-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.detail="{ item }">
        <v-btn
          icon
          outlined
          @click="applyRouterLink($routes.WITHDRAW_DETAIL.name, item)"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- ADD Dialog -->
    <v-dialog
      width="700"
      v-model="dialog.withdraw.show"
      fullscreen
      :key="dialog.withdraw.key"
    >
      <v-card>
        <v-card-title class="headline blue darken-2 white--text"
          >สร้างรายการเบิกเวชภัณฑ์ใหม่</v-card-title
        >
        <div class="pa-8">
          <v-select
            v-model="dialog.selected.objective"
            :items="dialog.objectiveList"
            label="เลือกประเภทการเบิก"
          />
          <v-text-field
            v-model="dialog.selected.withdrawBy"
            label="ชื่อผู้เบิก"
          />
        </div>
        <v-text-field
          v-model="dialog.searchText"
          label="ค้นหาเวชภัณฑ์"
          class="px-8"
          outlined
        />
        <div class="px-8 pt-4">รายการเวชภัณฑ์ที่เบิกได้</div>
        <div class="pa-8">
          <v-data-table
            v-model="dialog.selected.items"
            item-key="id"
            show-select
            :single-select="false"
            :items="getFilteredList"
            :headers="dialog.itemTable.header"
            :items-per-page="5"
            :footer-props="{
              'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
              pageText: 'แสดง {0}-{1} จาก {2}',
            }"
          >
            <template v-slot:item.quantity="{ item }">
              <v-text-field v-model="item.quantity"></v-text-field>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-btn
            tile
            rounded
            color="blue darken-2 white--text"
            @click="cancelHandler"
            >ยกเลิก</v-btn
          >
          <v-spacer />
          <span class="px-2"
            >ต้องการเบิก {{ dialog.selected.items.length }} รายการ</span
          >
          <v-btn
            tile
            rounded
            color="blue darken-2 white--text"
            @click="submitHandler"
            >บันทึกการเบิกเวชภัณฑ์</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
// const url = "http://localhost:8910/api";
// const url = "http://anantasolutions.co/flagship/api"
const url = "http://164.115.22.214/api"
export default {
  data: () => {
    return {
      tab: null,
      dialog: {
        withdraw: {
          show: false,
          key: 1,
        },
        itemTable: {
          header: [
            { text: "ชื่อสามัญ", value: "name" },
            { text: "หน่วยนับ", value: "unit" },
            { text: "จำนวนที่เบิกได้", value: "amount" },
            { text: "จำนวนที่ต้องการเบิก", value: "quantity" },
          ],
          items: [],
        },
        objectiveList: ["เบิกปกติ (เบิกไปห้องจ่ายยา)"],
        selected: {
          objective: null,
          withdrawBy: "",
          items: [],
        },
        searchText: "",
      },
      withdrawTable: {
        header: [
          {
            text: "วันที่ทำรายการ",
            value: "date",
          },
          {
            text: "ประเภทการเบิก",
            value: "objective",
          },
          {
            text: "ปลายทาง",
            value: "destination",
          },
          {
            text: "เอกสารที่เกี่ยวข้อง",
            value: "document",
          },
          // {
          //   text: "หมายเหตุ",
          //   value: "note",
          // },
          {
            text: "รายละเอียดรายการ",
            value: "detail",
          },
        ],
        items: [],
      },
      ongoingHomeVisitList: [
        {
          date: "28 พฤษภาคม 2563",
          destination: "ห้องจ่ายยา",
          objective: "ปกติ",
          status: "กำลังเตรียมการ",
          note: "",
        },
      ],
      itemList: [
        {
          id: 4,
          name: "Amoxycillin 500 mg",
          dosage: "250 mg,cap",
          packingSize: "1*500 s",
          unit: "กล่อง",
        },
        {
          id: 5,
          name: "Paracetamol 500 mg",
          dosage: "dry syrup125mg/ml",
          packingSize: "60 ml",
          unit: "กล่อง",
        },
      ],
    };
  },
  methods: {
    applyRouterLink(name, list) {
      this.$router.push({ name, params: { list: list } });
    },
    showWithdrawDialog() {
      this.dialog.withdraw.key += 1;
      this.dialog.withdraw.show = true;
    },
    initialize() {
      this.fetchWithdraw();
      // console.log("initialize");
      axios
        .post(url + "/inventory/records", { org_id: this.$cookies.get("user").hosp_code})
        .then((res) => {
          this.itemList = new Array();
          // console.log(res.data.inventory);
          res.data.inventory.forEach((item) => {
            this.itemList.push({
              id: item.inventory_id,
              generic_id: item.generic_id,
              name: item.generic_name,
              amount: item.amount,
              dosage: item.dosage,
              packingSize: item.packingSize,
              unit: item.unit,
            });
          });
          // console.log(this.itemList);

          this.dialog.itemTable.items = this.itemList;
          
        });
    },
    fetchWithdraw() {
      axios
        .post(url + "/pcoc/get_withdraw_list", { org_id: this.$cookies.get("user").hosp_code
 })
        .then((res) => {
          // console.log(res.data);
          this.ongoingHomeVisitList = new Array();
          if (res.data.status !== 'failed' && res.data.withdraw_list.length > 0) {
            res.data.withdraw_list.forEach((item) => {
              const remark = item.remark.split(",");
              this.ongoingHomeVisitList.push({
                date: new Date(item.date).toLocaleString("th-TH"),
                docNo: item.doc_no,
                destination: "ห้องยา",
                objective: remark[1],
                status: remark[0],
                note: remark[2],
                list: item.list,
              });
            });
          }

          this.withdrawTable.items = this.ongoingHomeVisitList;
        });
    },
    submitHandler() {
      this.addDialog = false;
      console.log("submit");
      console.log(this.dialog.selected);
      axios
        .post(url + "/pcoc/withdraw", {
          org_id: this.$cookies.get("user").hosp_code
,
          withdraw_list: this.dialog.selected,
        })
        .then((res) => {
          //   ,withdraw: selectedItems
          console.log(res.data);
          const item = res.data;
          var s_list = {
            date: item.withdraw_date,
            docNo: item.doc_no,
            destination:"ห้องยา",
            objective: item.objective,
            status: "",
            note: "",
            list: item.withdraw_list,
          };
          
          this.applyRouterLink(this.$routes.WITHDRAW_DETAIL.name,s_list);
        });
    },
    cancelHandler() {
      this.dialog.withdraw.show = false;
      this.dialog.selected.objective = null;
      this.dialog.selected.withdrawBy = null;
    },
  },
  computed: {
    getFilteredList() {
      return this.dialog.itemTable.items.filter((e) => {
        return e.name.toLowerCase().includes(this.dialog.searchText);
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>